.contactUs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.contact-form {
  width: 80%;
  padding: 30px;
  background: #e1e9ec;
}

.__title {
  text-transform: capitalize;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.__text {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.__input {
  display: flex;
}
.__input-field {
  width: 100%;
  display: inline-block;
  padding: 1rem 0.7rem;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 0.2rem;
  outline: none;
  margin-bottom: 12px;
  margin: 5px;
}

.__message {
  width: 100%;
  border: none;
  padding: 1rem 0.7rem;
}

.btn {
  padding: 7.5px;
  border: 1px solid #e1e9ec;
  border-radius: 10px;
  background-color: white;
  font-size: 16px;
}

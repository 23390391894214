.Nav {
  width: 100%;
  height: 10vh;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #b6b4b4;
  display: flex;
  justify-content: space-between;
}

.linkedin {
  width: 60px;
  margin-right: 20px;
}

.title {
  font-size: 32px;
  font-weight: 600;
  color: #71848a;
  margin: 0 10px;
}

.logoCon {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.navLogo {
  height: 90%;
}

@media screen and (max-width: 568px) {
  .navLogo {
    display: none;
  }
}

.ourMission {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 60px 0px;
  background-color: #e1e9ec;
}
.missionTitle {
  margin: 0;
  padding: 0;
  font-size: 42px;
  color: rgb(27, 38, 70);
}
.missionContainer {
  display: flex;
  justify-content: center;
}
.slideShow {
  width: 50%;
}
.missions {
  width: 50%;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 30px;
  padding-left: 40px;
}
.missionsListTitle {
  font-size: 22px;
  margin-top: 15px;
}
.missionsList {
  margin-top: 15px;
  line-height: 1.6;
  font-size: 20px;
  letter-spacing: 0.9px;
}
.slide {
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  transform: translateX(0);
}

li {
  list-style: circle;
}
@media screen and (max-width: 768px) {
  .missionContainer {
    flex-direction: column;
    width: 100%;
  }
  .slideShow {
    width: 100%;
  }
  .missions {
    width: 100%;
  }
}

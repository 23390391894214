.App {
}
.container {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileLogo {
  display: none;
}

.second {
  display: flex;
  width: 50%;
  height: 90vh;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.first {
  background-color: #e1e9ec;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 90vh;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  line-height: 1.5;
}
.pic1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mainTitle {
  font-size: 80px;
  color: rgb(27, 38, 70);
}
.definition {
  font-size: 24px;
  font-weight: 700;
  color: rgb(27, 38, 70);
}

.about {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  width: 100%;
  height: 30vh;
  background-color: #e1e9ec;
  display: flex;
}
#footer-logo {
  height: 100%;
}

.footer-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: 100%;
}

.copyright {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .second {
    display: none;
  }
  .first {
    width: 100%;
  }
  .definition {
    font-size: 20px;
    padding: 0 20px;
  }
  .footer {
    padding: 20px;
  }
  #footer-logo {
    display: none;
  }
  .footer-info {
    width: 100%;
  }
}
@media screen and (max-width: 568px) {
  .mobileLogo {
    display: block;
    width: 100%;
  }
  .container {
    height: 60vh;
  }
  .first,
  .second {
    height: 60vh;
  }
}
